define('app',[
    'js/de/initdata'
    ], function(initData) {
    'use strict';

    var Application = Backbone.Model.extend({
        "initialize": function() {
            this.initData = this.attributes.initData;
        },
        "isStudent": function() {
            var user = this.initData.get("user");

            // Python pages return a `user` object that contains the `is_student` property.
            // However, there are some ColdFusion pages that also return a `user` object,
            // but employ the `window.isStudent` property instead.
            // So we check for the existence of the `is_student` property, and, then fall
            // back to `window.isStudent` if it doesn't exist.
            if (user && 'is_student' in user) {
                return user.is_student;
            }

            return window.isStudent;
        },
        "isTeacher": function() {
            return !this.isStudent();
        },
        "apiUrls": function() {
            return window.DEAPIURLS;
        },
        "paths": function () {
            return window.paths || null;
        },
        "get_path": function (path_key, url_partial) {
            var paths = this.paths() || {},
                path_val;

            path_val = paths[path_key] || '';
            url_partial = url_partial || '';

            return path_val + url_partial;
        },
        "tei": function() {
            return window.TEI;
        },
        "teiClient": function() {
            return window.DETEIClient;
        },
        "toolsClient": function() {
            return window.DEToolsClient;
        },
        "rc": function() {
            return window.rc;
        }
    });
    return new Application({"initData": initData});
});

